import React, { useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import * as financialDates from 'http/financial-dates';
import Form from 'design/atoms/Form';
import variationLabels from 'util/variationLabels';

const makePeriodKey = period => {
	return `${period.label} ${period.taxYear}`;
};

const yearMonthDayToISODate = ({ year, month, day }) => {
	const formattedMonth = month.toString().padStart(2, '0');
	const formattedDay = day.toString().padStart(2, '0');
	return `${year}-${formattedMonth}-${formattedDay}`;
}

const InputReportingPeriodWidget = ({ label, tooltip, youtubeVideo, currentPeriod, onChange, supply, value }) => {
	const [periods, setPeriods] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const handlePeriodSelected = periodKey => {
		const { start, end } = periods.find(period => {
			return makePeriodKey(period) === periodKey;
		});

		onChange(supply, {
			periodStart: yearMonthDayToISODate(start),
			periodEnd: yearMonthDayToISODate(end),
		});
	};
	
	useEffect(() => {
		financialDates.getReportingPeriodChain()
			.then(chain => setPeriods(chain))
			.catch(err => setError(err))
			.finally(() => setLoading(false));
	}, []);

	const indexOfActivePeriod = periods.findIndex(period => {
		return makePeriodKey(period) === makePeriodKey(currentPeriod);
	});

	// periods are sorted in descending order, therefore active idx + 1
	const periodsBeforeCurrentPeriod = periods.slice(indexOfActivePeriod + 1);
	
	const currentlySelectedPeriod = value && periods.find(period => {
		return (
			value.periodStart === yearMonthDayToISODate(period.start) &&
			value.periodEnd === yearMonthDayToISODate(period.end)
		);
	});

	return (
		<Form.Row>
			<Form.LabelField
				label={label}
				tooltip={tooltip}
				youtubeVideo={youtubeVideo}
			/>
			<Form.Field error={!!error} textAlign='right'>
				<Dropdown
					key={loading}
					selection
					search
					fluid
					error={!!error}
					loading={loading}
					placeholder='Vælg et år...'
					value={currentlySelectedPeriod ? makePeriodKey(currentlySelectedPeriod) : undefined}
					onChange={(_, { value }) => handlePeriodSelected(value)}
					options={periodsBeforeCurrentPeriod.map(period => {
						const isConversionPeriod = period.label === variationLabels.conversion.id;

						return {
							text: period.taxYear,
							description: isConversionPeriod ? variationLabels.conversion.description : undefined,
							value: makePeriodKey(period),
						};
					})}
				/>
			</Form.Field>
		</Form.Row>
	);
};

export default InputReportingPeriodWidget;
