import React, { Component } from 'react';
import { formatNumber } from '../../../util/format/Number';
import styles from './ValueDisplay.module.scss';

const doFormatNumber = maybeNumber => {
    const number = Number(maybeNumber);

    if (isNaN(number)) {
        return maybeNumber;
    }

    if (Math.abs(Number(number)) < 10) {
        return new Intl.NumberFormat('da-DK').format(number);
    }

    return formatNumber(number);
}

class ValueDisplay extends Component {
    renderContent = () => {
        const { value, prefix, suffix, number } = this.props;

        let defaultValue = '';
        if (number) {
            defaultValue = 0;
        }

        let prefixValue;
        if (prefix) {
            prefixValue = <span>{prefix}</span>;
        }

        const valueToShow = value === null || value === undefined ? defaultValue : value;
        return <span>{prefixValue}{number ? doFormatNumber(valueToShow) : valueToShow} {suffix}</span>;
    };

    render () {
        const { float } = this.props;
        return (
            <div className={styles.valueContainer} style={{ float, textAlign: float }}>
                {this.renderContent()}
            </div>
        );
    }
}

export default ValueDisplay;
