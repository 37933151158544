import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form, Header, Icon, Input, Loader, Modal, Segment, Transition } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import * as fileStorage from 'http/file-storage';
import { downloadText } from 'util/downloadBlob';
import { readAsBase64 } from 'util/files';
import reportEditor from 'http/reportEditor';
import ColoredText from 'design/atoms/ColoredText';
import UploadLogo from 'design/molecules/UploadLogo';

const ReportThemeSettingsModal = ({ onClose, onSave }) => {
    const [theme, setTheme] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        reportEditor.getUserDefinedTheme()
            .then(theme => setTheme(theme || {}))
            .finally(() => setLoading(false));
    }, []);

    const handleClose = async () => {
        await reportEditor.putUserDefinedTheme(theme);
        onSave();
        onClose();
    };

    const updateTheme = (updator = {}) => {
        setTheme({
            ...theme,
            ...updator,
        });
    };

    const updateThemeColor = (colorKey, colorValue) => {
        const currentColors = theme.colors || {};
        updateTheme({
            colors: {
                ...currentColors,
                [colorKey]: colorValue,
            },
        });
    };

    const validateHexColor = (hexColor) => {
        if (!hexColor) return true;
        return /^#?([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hexColor);
    };

    return (
        <Modal open onClose={handleClose} closeIcon>
            <Modal.Header>
                <Icon name='cog' />
                Rapportindstillinger
            </Modal.Header>
            <Modal.Content>
                {loading && <Loader inline='centered' active />}
                {!loading && (
                    <Form>
                        <Form.Group widths='equal'>
                            <Form.Field error={!validateHexColor(theme.colors?.primary)}>
                                <label>Primær mærkefarve</label>
                                <Input
                                    onChange={(_, { value }) => updateThemeColor('primary', value)}
                                    defaultValue={theme.colors?.primary}
                                    error={!validateHexColor(theme.colors?.primary)}
                                    placeholder='Primær mærkefarve...'
                                />
                            </Form.Field>
                            <Form.Field error={!validateHexColor(theme.colors?.secondary)}>
                                <label>Sekundær mærkefarve</label>
                                <Input
                                    onChange={(_, { value }) => updateThemeColor('secondary', value)}
                                    defaultValue={theme.colors?.secondary}
                                    placeholder='Sekundær mærkefarve...'
                                />
                            </Form.Field>
                            <Form.Field error={!validateHexColor(theme.colors?.tertiary)}>
                                <label>Tertiær mærkefarve</label>
                                <Input
                                    onChange={(_, { value }) => updateThemeColor('tertiary', value)}
                                    defaultValue={theme.colors?.tertiary}
                                    placeholder='Tertiær mærkefarve...'
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Field>
                            <label>Font</label>
                            <Dropdown
                                selection
                                clearable
                                placeholder='Overwrite the default font...'
                                defaultValue={theme?.fontAssetID}
                                onChange={(_, { value }) => updateTheme({ fontAssetID: value })}
                                // matches fonts defined in the report-editor service at:
                                // src/routes/assets/static/'
                                options={[
                                    { text: 'Arial', value: 'font_arial.ttf' },
                                    { text: 'Open Sans', value: 'font_open_sans.ttf' },
                                    { text: 'Roboto', value: 'font_roboto.ttf' },
                                    { text: 'Segoe UI', value: 'font_segoe_ui.ttf' },
                                    { text: 'Tinos', value: 'font_tinos.ttf' },
                                ]}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Logo</label>
                            <UploadLogo />
                        </Form.Field>
                    </Form>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    color='black'
                    content='Luk'
                    onClick={handleClose}
                />
            </Modal.Actions>
        </Modal>
    );
};

const IXBRLViewer = ({ value, yearReportData, regenerateDocument, factsBeingUpdated, productMetadata, id, paymentURL, executionError }) => {
    const [virtualHTMLDocument, setVirtualHTMLDocument] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [yearReportDataExecutionStatus, setYearReportDataExecutionStatus] = useState('NOT_STARTED');
    const history = useHistory();

    const documentGeneratorProductFact = productMetadata.facts[id];
    const yearReportDataTriggerFactID = documentGeneratorProductFact.options.yearReportTriggerFact;

    useEffect(() => {
        switch (yearReportDataExecutionStatus) {
            case 'NOT_STARTED': {
                if (factsBeingUpdated.has(yearReportDataTriggerFactID)) {
                    setYearReportDataExecutionStatus('EXECUTING');
                }
                break;
            }

            case 'EXECUTING': {
                if (!factsBeingUpdated.has(yearReportDataTriggerFactID)) {
                    setYearReportDataExecutionStatus('DONE');
                }
                break;
            }

            default: break; // no-op
        }
    }, [factsBeingUpdated, yearReportDataExecutionStatus, yearReportDataTriggerFactID]);

    const virtualHTMLDocumentFileID = value?.virtualHTMLDocumentFileID;
    const inputHash = value?.inputHash;
    const reportTemplateID = yearReportData?.data?.reportTemplateID;
    const reportTemplateVersion = yearReportData?.data?.reportTemplateVersion;

    useEffect(() => {
        if (executionError) {
            setError(true);
            setLoading(false);
            return;
        }

        if (yearReportDataExecutionStatus !== 'DONE') return;
        if (!virtualHTMLDocumentFileID) return;
        if (!reportTemplateID) return;
        if (!reportTemplateVersion) return;
        
        setLoading(true);
        setError(false);

        fileStorage.getFileData(virtualHTMLDocumentFileID)
            .then((file) => {
                const bytes = Uint8Array.from(file.data.data);
                const rawJSON = new TextDecoder().decode(bytes);
                const virtualHTMLDocument = JSON.parse(rawJSON);
                setVirtualHTMLDocument(virtualHTMLDocument);
            })
            .catch(e => {
                console.error(e);
                setError(true);
            })
            .finally(() => setLoading(false));

    }, [virtualHTMLDocumentFileID, inputHash, reportTemplateID, reportTemplateVersion, factsBeingUpdated, yearReportDataExecutionStatus, executionError]);

    const handleDownloadIXBRL = async () => {
        setIsDownloading(true);
        setDownloadProgress(0);

        let ixbrlXHTML = value.temporaryIXBRL;

        const assetsPattern = new RegExp(reportEditor.getAssetURL('.+?'), 'g');

        const usedAssets = new Set();
        while (true) {
            const match = assetsPattern.exec(ixbrlXHTML);
            if (!match) break;
            usedAssets.add(match[0]);
        }

        let totalDownloaded = 0;

        await Promise.all([...usedAssets].map(async assetURL => {
            const assetResponse = await fetch(assetURL);
            if (!assetResponse.ok) {
                throw new Error(`Got ${assetResponse.status} fetching asset: ${assetURL}`);
            }

            const blob = await assetResponse.blob();
            const base64 = await readAsBase64(blob);

            const assetURLPattern = new RegExp(assetURL, 'g');

            ixbrlXHTML = ixbrlXHTML.replace(assetURLPattern, () => {
                return base64;
            });

            totalDownloaded++;

            setDownloadProgress(totalDownloaded / usedAssets.size * 100);
        }));

        downloadText('esg_report.xhtml', 'application/xhtml+xml', ixbrlXHTML);
    };

    const renderVirtualHTMLElement = virtualHTMLElement => {
        const {
            tag,
            style,
            children,
            ...other
        } = virtualHTMLElement;

        const props = {
            style,
            ...other,
        };

        if (virtualHTMLElement.class === 'purchaseLink') {
            props.onClick = () => history.push(paymentURL);
        }

        const convertedChildren = children.map(ele => {
            if (typeof ele === 'string') {
                return ele;
            }
            return renderVirtualHTMLElement(ele);
        });

        if (convertedChildren.length === 0) {
            return React.createElement(tag, props);
        }
        
        return React.createElement(tag, props, convertedChildren);
    };

    const contentIsReady = !loading && !error && virtualHTMLDocument;

    const documentWrapper = (
        <Transition visible={contentIsReady} duration={500} animation='fade'>
            <div>
                <div style={{ all: 'initial' }} id='documentWrapper'>
                    {virtualHTMLDocument && renderVirtualHTMLElement(virtualHTMLDocument)}
                </div>
            </div>
        </Transition>
    );

    const updatedAtTimestamp = new Date(value?.updatedAt).toLocaleString('da-DK', {
        day: 'numeric', 
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });

    let color;
    let icon;
    let text;
    if (loading) {
        color = 'grey';
        icon = 'spinner';
        text = 'Din ESG-rapport indlæses...';
    } else if (error) {
        color = 'red';
        icon = 'warning sign';
        text = 'Der opstod en fejl og din ESG-rapport kunne ikke indlæses...';
    } else {
        color = 'green';
        icon = 'check circle';
        text = 'Din ESG-rapport er klar';
    }

    return (
        <>
            <Segment color={color}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <Header>
                            <Icon
                                name={icon}
                                color={color}
                                loading={loading}
                            />
                            <Header.Content>
                                {text}
                                {contentIsReady && (
                                    <Header.Subheader>
                                        <b>Sidst opdateret:</b> {updatedAtTimestamp}
                                    </Header.Subheader>
                                )}
                            </Header.Content>
                        </Header>
                    </div>
                    {contentIsReady && (
                        <div style={{ flex: 1, textAlign: 'right' }}>
                            <ColoredText
                                content='Rapportindstillinger'
                                icon='cog'
                                iconPosition='left'
                                onClick={() => setSettingsModalOpen(true)}
                                underlined={false}
                                link
                            />&nbsp;&nbsp;
                            <Button
                                onClick={() => {
                                    handleDownloadIXBRL()
                                        .catch(() => toast.error('Dokumentet kunne ikke downloades...'))
                                        .finally(() => setIsDownloading(false));
                                }}
                                content={isDownloading ? `Downloading: ${downloadProgress.toFixed(2)} %` : 'Download som iXBRL'}
                                icon={<Icon name={isDownloading ? 'spinner' : 'download'} loading={isDownloading} />}
                                labelPosition='right'
                                disabled={isDownloading}
                                primary
                            />
                        </div>
                    )}
                </div>
            </Segment>
            {documentWrapper}
            {settingsModalOpen && (
                <ReportThemeSettingsModal
                    onClose={() => setSettingsModalOpen(false)}
                    onSave={async () => {
                        setYearReportDataExecutionStatus('NOT_STARTED');
                        setVirtualHTMLDocument(null);
                        setLoading(true);
                        regenerateDocument();
                    }}
                />
            )}
        </>
    );
};

export default IXBRLViewer;
