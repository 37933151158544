import { get } from 'lodash';
import intersection from 'lodash.intersection';

export const ADMIN = 'admin';
export const PRIVAT = 'privat';
export const ERHVERV = 'erhverv';
export const REVISOR = 'revisor';
export const HOLDING = 'holding';
export const DINERO_STAFF = 'dinero-staff';
export const EXTERNAL_MODEL_BUILDER = 'external-model-builder';
export const AKADEMI = 'akademi';
export const DYNACCOUNT_STAFF = 'dynaccount-staff';
export const ESG_BETATESTER = 'esg-betatester';
export const VIRKSOMHED = 'Virksomhed';
export const SELSKAB = 'Selskab';

export const SEGMENT_PRIVAT = 'privat';
export const SEGMENT_ERHVERV = 'erhverv';
export const SEGMENT_AKADEMI = 'akademi';

/**
 * @returns {boolean} t/f
 */
export function hasRole (user, role) {
    return user?.roles?.includes(role) || false;
}

export function isAdmin (user) {
    return hasRole(user, ADMIN);
}

export function isPrivat (user) {
    return hasRole(user, PRIVAT);
}

export function isErhverv (user) {
    return hasRole(user, ERHVERV);
}

export function isAccountant (user) {
    return hasRole(user, REVISOR);
}

export function isHolding (user) {
    return hasRole(user, HOLDING);
}

export function isDineroStaff (user) {
    return hasRole(user, DINERO_STAFF);
}

export function isAkademi (user) {
    return hasRole(user, AKADEMI);
}

export function isESGBetatester (user) {
    return hasRole(user, ESG_BETATESTER);
}

export function isCompany (user) {
    return !!user.cvr;
}

export function isSwedish (user) {
    return user.erp === 'swedishSIE';
}

export function isClient (user) {
    return !!user.creator;
}

export function isVirksomhed (user) {
    return user.businessCategory === VIRKSOMHED;
}

export function isSelskab (user) {
    return user.businessCategory === SELSKAB;
}

export function isSegmentPrivat(user) {
    return user?.login?.segment === SEGMENT_PRIVAT;
}

export function isSegmentErhverv(user) {
    return user?.login?.segment === SEGMENT_ERHVERV;
}

export function isSegmentAkademi(user) {
    return user?.login?.segment === SEGMENT_AKADEMI;
}

export function getUserType (user) {
    if (isErhverv(user)) {
        return ERHVERV;
    }

    return PRIVAT;
}

export function getReportLogo(user) {
    let logo = user.logo;
    if (isClient(user) && !logo) {
        logo = user.impersonatorData?.logo; // accountant's default logo
    } 
    return logo;
}

export function hasProductAccess (user, productID) {
    const { ownedProducts } = user;
    if (!ownedProducts) {
        return false;
    }
    const product = ownedProducts[productID];
    return !!product && product.length > 0;
}

/**
 * Checks if the user has access to the given product for the given tax year
 * @param {*} user
 * @param {string} productID
 * @param {string} taxYear
 */
export function ownsTaxYear (user, productID, taxYear) {
    const { ownedProducts } = user;
    if (!ownedProducts) {
        return false;
    }
    const product = ownedProducts[productID];
    return !!product && product.includes(`${taxYear}`);
}

/**
 * Gets an array of the products that the user has bought
 * @param {*} user
 * @returns {String[]} a list of product ids
 */
export function getPurchasedProducts (user) {
    const { ownedProducts } = user;
    if (!ownedProducts) {
        return [];
    }
    const out = [];
    for (let productID in ownedProducts) {
        if (ownedProducts[productID].length > 0) {
            out.push(productID);
        }
    }
    return out;
}

/**
 * Check if the user has an ACTIVE subscription (not if the user owns a tax year for the given product)
 * @param {*} user 
 * @param {string} productID 
 */
export function hasActiveSubscription (user, productID) {
    const { activeProducts } = user;
    if (!activeProducts) {
        return false;
    }
    return activeProducts.includes(productID);
}

export function isSuspended (user) {
    return !!user.suspended;
}

/**
 * Checks if the given user has the rights to view the provided product
 * @param {*} user 
 * @param {*} product 
 */
export function isAllowedToViewProduct (user, product) {
    const { allowedBusinessForms, roles } = product;

    // Check if the user has the correct business form
    if (allowedBusinessForms) {
        if (!allowedBusinessForms.includes(user.businessForm)) {
            return false;
        }
    }

    // Check if the user has one of the required roles
    if (intersection(user.roles, roles).length === 0) {
        return false;
    }

    if (['boligudlejning', 'solceller'].includes(product.id) && isCompany(user)) {
        return false;
    }
    
    return true;
}

/**
 * Gets a list of the users owned tax years
 * @param {*} user 
 * @param {*} productID 
 * @returns 
 */
export function getOwnedTaxYears (user, productID) {
    return get(user, `ownedProducts.${productID}`, []);
}